// extracted by mini-css-extract-plugin
export var active = "styles-module--active--07d12";
export var button = "styles-module--button--fcbb2";
export var content = "styles-module--content--3482e";
export var defaultText = "styles-module--defaultText--ef327";
export var disabled = "styles-module--disabled--7a04b";
export var display = "styles-module--display--6ec80";
export var fluid = "styles-module--fluid--a9fe0";
export var hoverBackground = "styles-module--hoverBackground--60aec";
export var hoverText = "styles-module--hoverText--07a82";
export var icon = "styles-module--icon--c6c13";
export var iconContainer = "styles-module--iconContainer--a102b";
export var left = "styles-module--left--68d60";
export var loader = "styles-module--loader--1ada1";
export var loaderContainer = "styles-module--loaderContainer--22368";
export var loading = "styles-module--loading--7ab9c";
export var primary = "styles-module--primary--e389f";
export var right = "styles-module--right--fb9db";
export var spin = "styles-module--spin--4331b";
export var text = "styles-module--text--4861c";
export var textContent = "styles-module--textContent--0d176";