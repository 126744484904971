import React from 'react';
import { usePaletteContext } from '../../../../context';
import { SVG } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  type: 'colors' | 'logos' | 'images' | 'text' | 'videos';
  id: string;
}

const SelectedContentWrapper = ({ children, id, type }: IProps) => {
  const { setPaletteContent } = usePaletteContext();

  const handleClick = () => {
    setPaletteContent((prev) => ({
      ...prev,
      [type]: prev[type].filter((item) => item.id !== id)
    }));
  };

  return (
    <div className={styles.container}>
      <button className={styles.deleteButton} onClick={handleClick}>
        <SVG svg="x" className={styles.xIcon} />
      </button>
      {children}
    </div>
  );
};

export default SelectedContentWrapper;
