// extracted by mini-css-extract-plugin
export var container = "styles-module--container--c61de";
export var content = "styles-module--content--4ceb9";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var paddingBottomRegular = "styles-module--paddingBottomRegular--333ad";
export var paddingBottomSmall = "styles-module--paddingBottomSmall--d4220";
export var paddingSidesRegular = "styles-module--paddingSidesRegular--3aafd";
export var paddingSidesSmall = "styles-module--paddingSidesSmall--f1bc7";
export var paddingTopRegular = "styles-module--paddingTopRegular--af930";
export var paddingTopSmall = "styles-module--paddingTopSmall--fcba9";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";