import React from 'react';

interface IProps {
  pageTitle: string;
  description?: string;
}

const SEO = ({ pageTitle, description }: IProps) => {
  const PROJECT_REF = process.env.GATSBY_SUPABASE_PROJECT_REF || '';

  const opengraphURL = `https://${PROJECT_REF}.supabase.co/storage/v1/object/public/images/finamp/_seo-images/open-graph.jpg?t=${new Date().getTime()}`;
  const faviconURL = `https://${PROJECT_REF}.supabase.co/storage/v1/object/public/images/finamp/_seo-images/favicon.png?t=${new Date().getTime()}`;

  // const siteTitle = title || 'ToolKit™';
  const siteUrl = process.env.GATSBY_SITE_URL || '';
  // const titleText =
  //   pageTitle === 'Home' ? siteTitle : `${pageTitle} | ${siteTitle}`;

  return (
    <>
      <title>{pageTitle}</title>
      <link rel="icon" type="image/png" href={faviconURL} />
      <meta name="description" content={description} />
      <link rel="canonical" href={siteUrl} />

      {/* facebook */}
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={opengraphURL} />
      <meta property="og:image:alt" content={pageTitle} />

      {/* twitter */}

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={opengraphURL} />
      <meta name="twitter:image:alt" content={pageTitle} />
    </>
  );
};

export default SEO;
