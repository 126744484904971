export { default as NoJs } from './Common/NoJs';

export { default as AnchorLinks } from './Common/AnchorLinks';
export { ColorTile } from './Common/ColorTile';
export { NumberButton } from './Common/NumberButton';
export { Partition } from './Common/Partition';
export { CopyText } from './Common/CopyText';
export { Video } from './Common/Video';
export { ColorGradientTile, type IGradient } from './Common/ColorGradientTile';
export { Password } from './Common/Password';
export { ImageCopyDownloadButtons } from './Common/ImageCopyDownloadButtons';
export { default as PageCategoryGroup } from './Common/Header/components/PageCategoryGroup';
export { default as PageLink } from './Common/Header/components/PageLink';
export { default as SliceConfig } from './Common/SliceConfig';
export { default as SliceHeader } from './Common/SliceHeader';
export { default as Tag } from './Common/Tag';
export { default as Grid } from './Common/Grid';
export { default as LayoutProvider } from './Common/LayoutProvider';
export { default as IntersectionAnimation } from './Common/IntersectionAnimation';
export { default as Button, type IButton } from './Common/Button';
export { default as PortableTextRenderer } from './Sanity/PortableTextRenderer';
export { default as TextInput } from './Common/TextInput';
export { default as SVG, svgs, type TSVG, type ISVG } from './Common/SVG';
export { default as Theme } from './Common/Theme';
export { default as TypeContainer } from './Common/TypeContainer';
export {
  default as TextAnimation,
  type TAnimation
} from './Common/TextAnimation';
export { default as Header } from './Common/Header';
export { default as ATFWidget } from './Common/ATFWidget';
export {
  default as SlidePillButtons,
  type ISlidePillButtonOption
} from './Common/SlidePillButtons';
export { default as ResizeTextArea } from './Common/ResizeTextArea';
export { default as WebComponentPreview } from './Common/WebComponentPreview';
export { default as LoadingScreen } from './Common/LoadingScreen';
export { default as PageNavButtons } from './Common/PageNavButtons';
export {
  default as ProductCard,
  type IProductCard
} from './Common/ProductCard';
export {
  default as SegmentedControl,
  type ISegmentedControlTab
} from './Common/SegmentedControl';
export { default as SanitySlice } from './Sanity/SanitySlice';
export { default as SEO } from './Common/SEO';
export { default as Footer } from './Common/Footer';
export { default as ScrollToTop } from './Common/ScrollToTop';
export {
  default as Notification,
  type INotification
} from './Common/Notification';
export { default as Link } from './Common/Link';
export { default as LinkTag } from './Common/LinkTag';
export { default as LoadingSpinner } from './Common/LoadingSpinner';
export { default as LogoFrame } from './Common/LogoFrame';
export {
  default as EditableTextField,
  type IEditableTextField
} from './Common/EditableTextField';
export { default as FileUpload } from './Common/FileUpload';
export { default as SlicePreview } from './Sanity/SlicePreview';
export { default as Image } from './Common/Image';
export {
  default as ImageWithCaption,
  type IImageWithCaption
} from './Common/ImageWithCaption';
export { default as AutoHeightTextArea } from './Common/AutoHeightTextArea';
export { default as DownloadLinks } from './Common/DownloadLinks';
export { PreviewGridButton } from './Common/PreviewGridButton';

// Figma
export { default as ColourInspector } from './Figma/ColourInspector';
export { default as ColourRow } from './Figma/ColourRow';
export { default as FigmaSlice } from './Figma/FigmaSlice';
export { default as GridVisualiser } from './Figma/GridVisualiser';
export { default as IconInspector } from './Figma/IconInspector';
export { default as ImageGrid } from './Figma/ImageGrid';
export { default as Imagery } from './Figma/Imagery';
export { BentoSection } from './Figma/BentoSection';
export { BentoBlock } from './Figma/BentoBlock';
export { ColourTile } from './Figma/ColourTile';
export { default as LogoFrames } from './Figma/LogoFrames';
export { default as LogoInspector } from './Figma/LogoInspector';
export { default as ProductGrid } from './Figma/ProductGrid';
export { default as ResourceCards } from './Figma/ResourceCards';
export { default as TextColumns } from './Figma/TextColumns';
export { default as TextImageGrid } from './Figma/TextImageGrid';
export { TwoColumnText } from './Figma/TwoColumnText';
export { MediaItem } from './Figma/MediaItem';
export { TextMediaColumns } from './Figma/TextMediaColumns';
export {
  default as TypeEditor,
  type ITypeEditorDefaultSettings
} from './Figma/TypeEditor';
export { default as TypeRow } from './Figma/TypeRow';
export { default as WebsitePrototype } from './Figma/WebsitePrototype';
export { default as WebStyleRow } from './Figma/WebStyleRow';

export { StyleTokens } from './StyleTokens';

/**************************************
 * Playground
 * */
export { default as PlaygroundWrapper } from './Playground/PlaygroundWrapper';
export type { TInspectorDirectory } from './Playground/PlaygroundWrapper';

// Components
import PgButton from './Playground/pgComponents/PgButton';
import PgTextInput from './Playground/pgComponents/PgTextInput';

export const PGComponents = Object.freeze({
  button: PgButton,
  textInput: PgTextInput
});

export type TPGComponent = keyof typeof PGComponents;

// Web Component
export { default as WebComponent } from './WebComponent';

// Slices
import PgHero from './Playground/pgSlices/PgHero';

export const PGSlices = Object.freeze({
  hero: PgHero
});

export type TPGSlice = keyof typeof PGSlices;
