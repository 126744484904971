import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { camelCaseToTitleCase } from '@lam-agency/toolkit/utils';
import * as styles from './styles.module.scss';
import { TextInput } from '@lam-agency/toolkit/components';
import { IToolkitSliceConfig } from '@lam-agency/sanity';
import { Button } from '@lam-agency/toolkit/components';

interface IProps {
  updateConfig: (field: string, value: any) => void;
  field: string;
}

const SchemaSliceConfig = ({ updateConfig, field }: IProps) => {
  const [values, setValues] = useState<IToolkitSliceConfig>({
    slicePadding: {
      paddingTop: `regular`,
      paddingBottom: `regular`,
      paddingX: `regular`
    },
    backgroundColor: {
      hex: `black`
    },
    textColor: {
      hex: `white`
    }
  });

  useEffect(() => {
    updateConfig(field, values);
  }, [values]);

  return (
    <>
      <div className={cn(`caption`, styles.schemaLabel)}>
        {camelCaseToTitleCase(field)}
      </div>

      <div className={styles.container}>
        <div className={cn(`caption`, styles.schemaLabel)}>Padding Top</div>
        <div className={styles.buttons}>
          <Button
            variant={
              values.slicePadding?.paddingTop === `regular`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingTop: `regular`
                }
              }))
            }
          >
            Regular
          </Button>
          <Button
            variant={
              values.slicePadding?.paddingTop === `small`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingTop: `small`
                }
              }))
            }
          >
            Small
          </Button>
          <Button
            variant={
              values.slicePadding?.paddingTop === `none`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingTop: `none`
                }
              }))
            }
          >
            None
          </Button>
        </div>

        <div className={cn(`caption`, styles.schemaLabel)}>Padding Sides</div>
        <div className={styles.buttons}>
          <Button
            variant={
              values.slicePadding?.paddingX === `regular`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingX: `regular`
                }
              }))
            }
          >
            Regular
          </Button>
          <Button
            variant={
              values.slicePadding?.paddingX === `small`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingX: `small`
                }
              }))
            }
          >
            Small
          </Button>
          <Button
            variant={
              values.slicePadding?.paddingX === `none` ? `primary` : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingX: `none`
                }
              }))
            }
          >
            None
          </Button>
        </div>

        <div className={cn(`caption`, styles.schemaLabel)}>Padding Bottom</div>
        <div className={styles.buttons}>
          <Button
            variant={
              values.slicePadding?.paddingBottom === `regular`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingBottom: `regular`
                }
              }))
            }
          >
            Regular
          </Button>
          <Button
            variant={
              values.slicePadding?.paddingBottom === `small`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingBottom: `small`
                }
              }))
            }
          >
            Small
          </Button>
          <Button
            variant={
              values.slicePadding?.paddingBottom === `none`
                ? `primary`
                : `secondary`
            }
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                slicePadding: {
                  ...prev.slicePadding,
                  paddingBottom: `none`
                }
              }))
            }
          >
            None
          </Button>
        </div>

        <div className={cn(`caption`, styles.schemaLabel)}>
          Background Color
        </div>
        <TextInput
          className={styles.input}
          value={values.backgroundColor?.hex}
          onChange={(value) =>
            setValues((prev) => ({
              ...prev,
              backgroundColor: {
                hex: value
              }
            }))
          }
        />

        <div className={cn(`caption`, styles.schemaLabel)}>Text Color</div>
        <TextInput
          className={styles.input}
          value={values.textColor?.hex}
          onChange={(value) =>
            setValues((prev) => ({
              ...prev,
              textColor: {
                hex: value
              }
            }))
          }
        />
      </div>
    </>
  );
};

export default SchemaSliceConfig;
