import React from 'react';
import { Helmet } from 'react-helmet';
import getFontFamilyCss from './getFontFamilyCss';
import getTypestyleCss from './getTypestyleCss';
import { useMVT } from '@lam-agency/toolkit/stores';

const Fonts = () => {
  const {
    projectString,
    variables: { fonts },
    client,
    typestyles
  } = useMVT();

  return (
    <Helmet>
      {fonts.map((font, i) => {
        const fontAssetPath = `${projectString || ''}/${font.fileName}`;

        const fontAssetUrl = client?.storage
          .from('fonts')
          .getPublicUrl(fontAssetPath).data.publicUrl;

        return (
          <link
            key={i}
            rel="preload"
            href={fontAssetUrl}
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
        );
      })}

      <style>{getFontFamilyCss({ client, fonts, projectString })}</style>
      <style>{getTypestyleCss(typestyles)}</style>
    </Helmet>
  );
};

export default Fonts;
