import React from 'react';
import { Button, LayoutProvider } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';

const Footer = () => {
  return (
    <footer className={styles.container}>
      <LayoutProvider paddingX paddingY grid className={styles.grid}>
        <SVG svg="logo" className={styles.wordmark} />

        <div className={styles.contentContainer}>
          <Button
            to="http://www.loveandmoney.agency/toolkit"
            className={styles.button}
            color="white"
          >
            Made with ToolKit™
          </Button>

          <p className="caption">
            © {new Date().getFullYear()}. fin.amp is the operating name of
            Fintech Fund Management Co Pty Ltd, ACN 678 455 760, CAR No
            001311415
          </p>
        </div>
      </LayoutProvider>
    </footer>
  );
};

export default Footer;
