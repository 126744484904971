import React from 'react';

import { ReactComponent as placeholder } from './icons/placeholder.svg';

export const brandIcons = Object.freeze({
  'Placeholder Icon': placeholder
});

export type TBrandIcon = keyof typeof brandIcons;

export interface IBrandIcon {
  icon: TBrandIcon;
  className?: string;
  style?: React.CSSProperties;
}

export const BrandIcon = ({ icon, ...props }: IBrandIcon) => {
  if (!brandIcons[icon]) return null;

  const SVGElement = brandIcons[icon];

  return <SVGElement {...props} aria-hidden />;
};
