/**
 * Helper util to generate a groq query for a slice.
 * All that needs to be passed in are the query fields.
 * sliceConfig is included by default, so doesn't need to be added every time.
 *
 * example usage:
 *
 * const articleGroq = getSliceGroqQuery(`
 *   headerText,
 *   header {
 *     content,
 *     style
 *   }
 * `);
 */

export const sliceConfigFragment = `
  sliceConfig {
    ...,
    backgroundColor-> {
      hex
    },
    textColor-> {
      hex
    },
  },
`;

export const getSliceGroqQuery = (fields: string) => `
*[_type == $pageType && slug.current == $pageSlug][0].pagebuilder.slices[$sliceIndex] {
  _key,
  _type,

  ${sliceConfigFragment}
  
  ${fields}  
}`;
