import React, { Fragment } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { TElement } from '../..';
import { useInView } from 'react-intersection-observer';

interface IProps {
  text: string;
  className?: string;
  element?: TElement;
}

export const ColorBump = ({ text, className, element = 'p' }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: '-90px',
    triggerOnce: true
  });

  const Element = element;

  const lineTerminatorChar = /\n/g;

  const splitByLines = text.split(lineTerminatorChar);
  const lines = splitByLines.map((line) => line.split(' '));

  const getDelaySeconds = (lineIndex: number, wordIndex: number) => {
    const STAGGER_S = 0.05;

    const previousWords = lines[lineIndex].slice(0, wordIndex);
    const previousLines = lines.slice(0, lineIndex);
    const previousWordCount = previousLines.reduce(
      (acc, line) => acc + line.length,
      0
    );

    return previousWordCount * STAGGER_S + previousWords.length * STAGGER_S;
  };

  return (
    <Element ref={ref} className={cn(styles.container, className)}>
      {lines.map((words, lineIndex) => (
        <Fragment key={lineIndex}>
          {lineIndex > 0 && <>{`\n`}</>}

          {words.map((word, wordIndex) => (
            <span className={styles.wordContainer} key={wordIndex}>
              <span
                style={{
                  animationDelay: `${getDelaySeconds(lineIndex, wordIndex)}s`
                }}
                className={cn(styles.word, {
                  [styles.active]: inView
                })}
              >
                {word}
              </span>
              {wordIndex < words.length - 1 && <span>{` `}</span>}
            </span>
          ))}
        </Fragment>
      ))}
    </Element>
  );
};
