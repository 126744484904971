import { graphql, useStaticQuery } from 'gatsby';
import useApp from './useApp';

interface IPageNode {
  slug: {
    current: string;
  };
  invertColorScheme: boolean;
}

type TColorScheme = `light` | `dark`;

export const usePageColorScheme: () => TColorScheme = () => {
  const { pathname } = useApp();

  const data = useStaticQuery(graphql`
    query ColorSchemeQuery {
      allSanityToolkitPage {
        nodes {
          slug {
            current
          }
          invertColorScheme
        }
      }
    }
  `);

  if (!pathname || !data?.allSanityToolkitPage?.nodes) {
    return `light`;
  }

  const pageNodes: IPageNode[] = data.allSanityToolkitPage.nodes;

  const currentPageSlug = pathname.split(`/`)[1];

  const matchingPageNode = pageNodes.find(
    (node) => node.slug.current === currentPageSlug
  );

  return matchingPageNode?.invertColorScheme ? `light` : `dark`;
};

export default usePageColorScheme;
