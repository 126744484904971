import React from 'react';
import {
  LayoutProvider,
  ResourceCards,
  SliceHeader
} from '@lam-agency/toolkit/components';
import { ISliceLinksAndResources } from 'lam-toolkit-figma-plugin';

const FigmaLinksAndResources = ({
  sections,
  sliceHeader
}: ISliceLinksAndResources) => {
  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      {sections &&
        sections.map((section, i) => <ResourceCards key={i} data={section} />)}
    </LayoutProvider>
  );
};

export default FigmaLinksAndResources;
