import { imageDataURLToBlob } from '../utils';
import useApp from './useApp';

export const useCopyToClipboard = () => {
  const { updateNotification } = useApp();

  const copyStringToClipboard = (string: string, notificationText: string) => {
    navigator.clipboard.writeText(string);
    updateNotification(notificationText);
  };

  const copyImageToClipboard = async (
    imageURL: string,
    notificationText: string
  ) => {
    const response = await fetch(imageURL);
    const blob = await response.blob();

    if (blob.type !== 'image/png') {
      const img = await createImageBitmap(blob);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((canvasBlob) => {
        if (!canvasBlob) {
          throw new Error('Failed to get canvas blob');
        }

        navigator.clipboard.write([
          new ClipboardItem({
            [canvasBlob.type]: canvasBlob
          })
        ]);
      });
    } else {
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
    }

    updateNotification(notificationText);
  };

  const copySVGToClipboardAsText = async (svgURL: string) => {
    try {
      const response = await fetch(svgURL);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const svgText = await response.text();

      await navigator.clipboard.writeText(svgText);

      updateNotification('SVG text copied to clipboard');
    } catch (error) {
      console.error('Failed to copy SVG to clipboard: ', error);
      alert('Failed to copy SVG. Please try again.');
    }
  };

  const copyImageToClipboardFromDataURL = async (dataURL: string) => {
    try {
      const blob = await imageDataURLToBlob(dataURL);

      if (!blob) {
        throw new Error('Failed to convert image to blob');
      }

      const item = new ClipboardItem({
        [blob.type]: blob
      });

      await navigator.clipboard.write([item]);

      updateNotification('Copied image to clipboard');
    } catch (error) {
      console.error('Failed to copy image: ', error);
      alert('Failed to copy image. Please try again.');
    }
  };

  return {
    copyStringToClipboard,
    copyImageToClipboard,
    copySVGToClipboardAsText,
    copyImageToClipboardFromDataURL
  };
};
