import React, { Fragment } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useInView } from 'react-intersection-observer';
import { TElement } from '../..';

interface IProps {
  text: string;
  element?: TElement;
  className?: string;
  speed?: number;
}

export const FadeUp = ({
  text,
  className,
  speed = 0.02,
  element = 'p'
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: '-90px',
    triggerOnce: true
  });

  const Element = element;

  const lineTerminatorChar = /\u2028/g; // shift + enter in Figma

  const splitIntoLines = text.split(lineTerminatorChar);
  const splitIntoWords = splitIntoLines.map((line) => line.split(' '));
  const splitIntoLetters = splitIntoWords.map((word) =>
    word.map((letter) => letter.split(''))
  );

  const getLetterPositionInText = (
    lineIndex: number,
    wordIndex: number,
    letterIndex: number
  ) => {
    let charCount = 0;
    for (let i = 0; i < lineIndex; i++) {
      lines[i].forEach((word) => {
        charCount += word.length;
      });
    }
    for (let j = 0; j < wordIndex; j++) {
      charCount += lines[lineIndex][j].length;
    }
    charCount += letterIndex;

    return charCount;
  };

  const getDelay = (
    lineIndex: number,
    wordIndex: number,
    letterIndex: number
  ) => {
    const lettersInText = text
      .split('')
      .filter((char) => ![' ', '\n'].includes(char)).length;

    const numberOfLettersToSlowDownAtEnd = 10;
    const exponentialFactor = 1.38;

    const letterPositionInText = getLetterPositionInText(
      lineIndex,
      wordIndex,
      letterIndex
    );

    const isInLastLetters =
      letterPositionInText >= lettersInText - numberOfLettersToSlowDownAtEnd;

    const delay = speed * letterPositionInText;

    if (isInLastLetters) {
      const positionInLastLetters =
        letterPositionInText -
        (lettersInText - numberOfLettersToSlowDownAtEnd) +
        1;

      const exponentialSlowdown =
        Math.pow(exponentialFactor, positionInLastLetters - 1) * speed;

      const combinedDelaySeconds = delay + exponentialSlowdown;

      return `${combinedDelaySeconds}s`;
    } else {
      return `${delay}s`;
    }
  };

  const lines = splitIntoLetters;

  return (
    <Element ref={ref} className={cn(styles.fadeUp, className)}>
      {lines.map((words, lineIndex) => (
        <span className={styles.line} key={lineIndex}>
          {words.map((letters, wordIndex) => (
            <Fragment key={wordIndex}>
              {wordIndex > 0 && <span> </span>}
              <span className={styles.word}>
                {letters.map((letter, letterIndex) => (
                  <span key={letterIndex} className={styles.letterWrapper}>
                    <span
                      style={{
                        animationDelay: getDelay(
                          lineIndex,
                          wordIndex,
                          letterIndex
                        )
                      }}
                      className={cn(styles.letter, inView && styles.visible)}
                    >
                      {letter}
                    </span>
                  </span>
                ))}
              </span>
            </Fragment>
          ))}
        </span>
      ))}
    </Element>
  );
};
