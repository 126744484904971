import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { Button } from '../..';
import { useWindowDimensions } from '@lam-agency/toolkit/hooks';

export interface ISlidePillButtonOption {
  label: string;
  value: string;
  active: boolean;
}

interface IProps {
  options: ISlidePillButtonOption[];
  setOptions: React.Dispatch<React.SetStateAction<ISlidePillButtonOption[]>>;
  className?: string;
}

interface IPillBackgroundPosition {
  top?: number;
  left?: number;
  width?: number;
  height?: number;
}

const SlidePillButtons = ({ options, setOptions, className }: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const activeOption = options.find((option) => option.active);

  const [pillBackgroundPosition, setPillBackgroundPosition] =
    useState<IPillBackgroundPosition>({ height: 0, left: 0, top: 0, width: 0 });

  const updatePillBackgroundPosition = useCallback(() => {
    const getPillBackgroundPosition: () => IPillBackgroundPosition = () => {
      if (!containerRef.current) return {};

      const activeButton = containerRef.current.querySelector(
        `[data-value="${activeOption?.value}"]`
      ) as HTMLButtonElement;

      if (!activeButton) return {};

      const left = activeButton.offsetLeft;
      const top = activeButton.offsetTop;
      const width = activeButton.offsetWidth;
      const height = activeButton.offsetHeight;

      return {
        height,
        left,
        top,
        width
      };
    };

    setPillBackgroundPosition(getPillBackgroundPosition());
  }, [activeOption?.value]);

  useEffect(() => {
    updatePillBackgroundPosition();
  }, [activeOption, updatePillBackgroundPosition]);

  useEffect(() => {
    setTimeout(updatePillBackgroundPosition, 100);
  }, [updatePillBackgroundPosition]);

  const handleUpdateActiveOption = (option: ISlidePillButtonOption) => {
    setOptions((prev) =>
      prev.map((prevOption) => ({
        ...prevOption,
        active: prevOption.value === option.value
      }))
    );
  };

  return (
    <div ref={containerRef} className={cn(styles.container, className)}>
      <div className={styles.pillBackground} style={pillBackgroundPosition} />
      {options.map((option, i) => (
        <button
          key={i}
          data-value={option.value}
          type="button"
          className={cn(styles.button, {
            [styles.active]: activeOption?.value === option.value
          })}
          onClick={() => handleUpdateActiveOption(option)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

const SlidePillButtonsWithMobileFallback = ({
  options,
  setOptions,
  className
}: IProps) => {
  const [pillButtonContentWidth, setPillButtonContentWidth] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowDimensions();

  const calculateContentWidth = () => {
    if (!contentRef.current) return;

    const contentWidth = contentRef.current.offsetWidth;

    setPillButtonContentWidth(contentWidth);
  };

  useEffect(() => {
    calculateContentWidth();
  }, [width]);

  const paddingPX = 40;

  const useMobileFallback = pillButtonContentWidth > width - paddingPX;

  return (
    <div className={styles.wrapperContainer}>
      {!useMobileFallback && (
        <div ref={contentRef} className={styles.wrapperContent}>
          <SlidePillButtons
            options={options}
            setOptions={setOptions}
            className={className}
          />
        </div>
      )}

      {useMobileFallback && (
        <div className={styles.mobileButtons}>
          {options.map((button, i) => (
            <Button
              onClick={() =>
                setOptions(
                  options.map((option) => ({
                    ...option,
                    active: option.value === button.value
                  }))
                )
              }
              fluid
              variant="primary"
              active={button.active}
              key={i}
            >
              {button.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SlidePillButtonsWithMobileFallback;
