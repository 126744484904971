import React from 'react';
import cn from 'classnames';
import {
  NoJs,
  Theme,
  Header,
  Footer,
  StyleTokens
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import 'modern-normalize/modern-normalize.css';
import '@lam-agency/toolkit/styles/global.css';
import { useSyncMVT } from '../hooks';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className }: IProps) => {
  useSyncMVT();

  return (
    <>
      <NoJs />
      <Theme />
      <StyleTokens />

      <MainPageLayout className={className}>{children}</MainPageLayout>
    </>
  );
};

export default Layout;

const MainPageLayout = ({ children, className }: IProps) => {
  return (
    <>
      <Header />

      <div className={cn(styles.container, className)}>{children}</div>

      <Footer />
    </>
  );
};
