import React, { useState } from 'react';
import { SliceConfig } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { useBreakpoints } from '@lam-agency/toolkit/hooks';
import { IToolkitHomepageAtf } from '@lam-agency/sanity';
import { SVG, TSVG } from '@lam-agency/toolkit/components';
import AsciiCanvas from './components/AsciiCanvas';
import SettingsWidget from './components/SettingsWidget';

export interface ISettings {
  characters: string;
  textColor: string;
  backgroundColor: string;
  blendMode: boolean;
}
interface IProps {
  data: IToolkitHomepageAtf;
}

const HomepageATF = ({ data: { sliceConfig } }: IProps) => {
  const [settings, setSettings] = useState<ISettings>({
    characters: `<3+$`,
    textColor: `var(--color-black-40)`,
    backgroundColor: `var(--color-black-90)`,
    blendMode: false
  });

  const { largeTablet } = useBreakpoints();

  const LSvg: TSVG = largeTablet ? 'atfL' : 'atfLRotated';
  const MSvg: TSVG = largeTablet ? 'atfM' : 'atfMRotated';

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: settings.backgroundColor }}
    >
      <div className={styles.ascii}>
        <AsciiCanvas settings={settings} />
      </div>
      <SliceConfig
        config={{
          ...sliceConfig,
          slicePadding: {
            paddingBottom: 'small',
            paddingTop: 'small'
          },
          backgroundColor: {
            hex: `transparent`
          }
        }}
      >
        <div className={styles.content}>
          <div
            className={styles.lamLetters}
            style={{
              color: settings.textColor,
              mixBlendMode: settings.blendMode ? `exclusion` : `normal`
            }}
          >
            <SVG svg={LSvg} className={styles.lamLetter} />
            <SVG svg={MSvg} className={styles.lamLetter} />
          </div>
        </div>

        <SettingsWidget setSettings={setSettings} settings={settings} />
      </SliceConfig>
    </div>
  );
};

export default HomepageATF;
