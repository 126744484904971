import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { isExternalUrl } from '@lam-agency/toolkit/utils';

interface IProps {
  url: string;
  className?: string;
  children: React.ReactNode;
}

const Link = ({ url, className, children }: IProps) => {
  if (!url) return null;

  const isExternal = isExternalUrl(url);

  if (isExternal) {
    return (
      <a
        className={className}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink className={className} to={url}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
