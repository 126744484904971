import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { Button, TextInput } from '@lam-agency/toolkit/components';
import PostPreview from '../../components/PostPreview';
import useMagicPenContext from '../../context';
import { SVG } from '@lam-agency/toolkit/components';

interface IPostContent {
  platformTitle: string;
  content: string;
}

const PostResults = () => {
  const { setPostPlatforms, postPlatforms, setPostTopic, postTopic } =
    useMagicPenContext();

  const getInitialPostContent: () => IPostContent[] = () => {
    return postPlatforms.map((platform) => ({
      platformTitle: platform.title,
      content: ''
    }));
  };

  const [postContent, setPostContent] = useState(getInitialPostContent());
  const [isEditingTopic, setIsEditingTopic] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const prevTopicRef = useRef(postTopic);

  const handleSelectPlatform = (platformTitle: string) => {
    setPostPlatforms((prev) =>
      prev.map((platform) => ({
        ...platform,
        selected: platform.title === platformTitle ? true : false
      }))
    );
  };

  const updatePostContentValue = ({
    platform,
    value
  }: {
    platform: string;
    value: string;
  }) => {
    setPostContent((prev) =>
      prev.map((prevPostContent) => ({
        ...prevPostContent,
        content:
          prevPostContent.platformTitle === platform
            ? value
            : prevPostContent.content
      }))
    );
  };

  const activePostPlatform = postPlatforms.find(
    (platform) => platform.selected
  );

  const activePostContent = postContent.find(
    (content) => content.platformTitle === (activePostPlatform?.title || '')
  )?.content;

  const getPostContent = async () => {
    if (!activePostPlatform) return;

    const activePostTitle = activePostPlatform.title;

    const fullPrompt = `Create a post about this topic: ${postTopic}. Include a call to action, e.g. "Read the full awesome story via the link in bio."`;

    updatePostContentValue({ value: '', platform: activePostTitle });

    const response = await fetch('/.netlify/functions/chat-gpt', {
      method: 'POST',
      body: JSON.stringify({ prompt: fullPrompt })
    });

    const data = await response.text();

    updatePostContentValue({ value: data, platform: activePostTitle });
  };

  const handleUpdateActivePostContent = (updatedContent: string) => {
    setPostContent((prev) =>
      prev.map((content) => ({
        platformTitle: content.platformTitle,
        content:
          content.platformTitle === activePostPlatform?.title
            ? updatedContent
            : content.content
      }))
    );
  };

  const handleSaveNewTopic = () => {
    setIsEditingTopic(false);

    if (postTopic !== prevTopicRef.current) {
      prevTopicRef.current = postTopic;
      getPostContent();
    }
  };

  useEffect(() => {
    setIsEditing(false);

    if (!activePostPlatform || activePostContent) return;

    getPostContent();
  }, [activePostPlatform]);

  return (
    <>
      <div className={styles.container}>
        <div>
          <div>
            <h2 className={cn('h2', styles.title)}>Creating a post about...</h2>

            <div className={styles.promptContainer}>
              {!isEditingTopic && (
                <>
                  <div className={styles.editButtonContainer}>
                    <button
                      onClick={() => setIsEditingTopic(true)}
                      className={cn('b2', styles.editButton)}
                    >
                      <SVG svg="edit" className={styles.editIcon} />
                    </button>
                  </div>

                  <p className={cn('b1')}>{postTopic}</p>
                </>
              )}

              {isEditingTopic && (
                <div className={styles.editInputContainer}>
                  <TextInput
                    textarea
                    value={postTopic}
                    onChange={setPostTopic}
                    className={styles.textInput}
                  />

                  <Button iconLeft="check" onClick={handleSaveNewTopic}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>

          {!isEditingTopic && (
            <div>
              <h2 className={cn('h2', styles.title)}>For...</h2>

              <div className={styles.platformButtons}>
                {postPlatforms.map((platform, i) => (
                  <Button
                    key={i}
                    variant={platform.selected ? 'primary' : 'secondary'}
                    onClick={() => handleSelectPlatform(platform.title)}
                  >
                    {platform.title}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>

        <PostPreview
          className={styles.postPreview}
          getPostContent={getPostContent}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          activePostContent={activePostContent || ''}
          platform={activePostPlatform?.title || ''}
          handleUpdateActivePostContent={handleUpdateActivePostContent}
        />
      </div>
    </>
  );
};

export default PostResults;
