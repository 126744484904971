import React from 'react';
import { Helmet } from 'react-helmet';
import { useMVT } from '@lam-agency/toolkit/stores';

const Colors = () => {
  const { colors } = useMVT();

  let colorsCss = `:root{`;

  colors.forEach(({ hex, name }) => {
    colorsCss += `--color-${name}: ${hex};`;
  });

  // todo: maybe do this differently?
  const uxColorsCss = `--color-ux-success: #07CB9C;--color-ux-error: #F75757;`;

  colorsCss += uxColorsCss;

  colorsCss += `}`;

  return (
    <Helmet>
      <style>{colorsCss}</style>
    </Helmet>
  );
};

export default Colors;
