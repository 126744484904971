import ReactDOMServer from 'react-dom/server';

export * from './getSliceGroqQuery';
export * as localStorage from './localStorage';

export const isClient = typeof window === `undefined` ? false : true;

export const remToPx = (rem: number) => {
  if (typeof window === `undefined`) {
    return rem;
  }

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

interface IRBG {
  red: number;
  green: number;
  blue: number;
}

export const hexToRGB: (hex: string) => IRBG = (hex) => {
  const hexWithoutHash = hex.replace(/^#/, '');

  // Convert the background color to RGB
  const red = parseInt(hexWithoutHash.substring(0, 2), 16);
  const green = parseInt(hexWithoutHash.substring(2, 4), 16);
  const blue = parseInt(hexWithoutHash.substring(4, 6), 16);

  return {
    red,
    green,
    blue
  };
};

export const getTextThemeByBackgroundHex: (hex: string) => 'dark' | 'light' = (
  hex
) => {
  const rgb = hexToRGB(hex);
  const { blue, green, red } = rgb;

  // Calculate the brightness using the formula: (R * 299 + G * 587 + B * 114) / 1000
  const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

  return brightness > 128 ? 'dark' : 'light';
};

export const capitalizeString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const spliceFromStateArray = (array: any[], item: any) => {
  if (!array?.[0] || !array?.includes(item)) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(array.indexOf(item), 1);

  return arrayClone;
};

export const spliceFromStateArrayByProperty = (
  array: any[],
  key: string,
  value: any
) => {
  if (!array?.[0]) {
    return array;
  }

  const item = array?.find((arrayItem) => arrayItem?.[key] === value);

  if (!item) {
    return array;
  }

  return spliceFromStateArray(array, item);
};

export const spliceFromStateArrayByIndex = (array: any[], index: number) => {
  if (!array?.[0] || !array?.[index]) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(index, 1);

  return arrayClone;
};

export const getRandomIntByRange = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const sortArrayByNumberProperty = (array: any[], property: string) => {
  if (!array?.[0]?.[property]) {
    return array;
  }

  return array.sort((a, b) => {
    const itemA = a?.[property] || 9999999999;
    const itemB = b?.[property] || 9999999999;

    if (itemA < itemB) {
      return -1;
    }

    if (itemA > itemB) {
      return 1;
    }

    return 0;
  });
};

export const splitCamelCase = (word: string) =>
  word.replace(/([A-Z])/g, ` $1`).replace(/^./, (str) => str.toUpperCase());

export const getCurrentLink = (pathname: string) => pathname;

export const rotisserieCamel = (camel: string) =>
  camel.replace(/([A-Z0-9])/g, `-$1`).toLowerCase();

export const camelCaseToTitleCase = (camelCase: string) => {
  const splitToWords = camelCase.replace(/([A-Z])/g, ` $1`);
  const capitalise =
    splitToWords.charAt(0).toUpperCase() + splitToWords.slice(1);
  return capitalise;
};
export const dashToCamel = (dashStr: string) =>
  dashStr.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      resolve(result);
    };
    reader.onerror = () => {
      reject(new Error('Error reading the file'));
    };
    reader.readAsDataURL(file);
  });
};

export const getFontWeightFromStyle = (fontStyle: string) => {
  switch (fontStyle) {
    case 'Thin':
      return 100;
    case 'Extra Light':
    case 'Ultra Light':
      return 200;
    case 'Light':
    case 'Semilight':
      return 300;
    case 'Regular':
      return 400;
    case 'Medium':
      return 500;
    case 'Semi Bold':
    case 'SemiBold':
      return 600;
    case 'Bold':
      return 700;
    case 'Extra Bold':
    case 'Ultra Bold':
      return 800;
    case 'Black':
    case 'Heavy':
      return 900;
    default:
      return 400;
  }
};

export const isExternalUrl = (url: string) => {
  if (!url) return false;

  const containsUrlScheme = url?.match(/(http|https|#|tel:|mailto:)/);
  const containsSiteUrl =
    process.env.GATSBY_SITE_URL &&
    url?.indexOf(process.env.GATSBY_SITE_URL) !== -1;

  const isExternal = containsUrlScheme && !containsSiteUrl;

  return isExternal;
};

export const toKebabCase = (string: string) => {
  return (
    string
      // Replace spaces with a single hyphen
      .replace(/\s+/g, '-')
      // Replace underscores with a single hyphen
      .replace(/_/g, '-')
      // Remove characters - apostrophes (can add characters to array as needed)
      .replace(/[']/g, '')
      // Insert a hyphen between lowercase to uppercase transitions
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      // Lowercase the entire string
      .toLowerCase()
  );
};

export const getWhiteIfBackgroundIsTransparent = () => {
  return undefined;
};

export const svgToDataURL = (svgComponent: React.ReactElement): string => {
  // convert the svg component to a string
  const svgString = ReactDOMServer.renderToString(svgComponent);

  // convert the SVG string to a UTF-8 encoded Uint8Array.
  const utf8Encoder = new TextEncoder();
  const svgUint8Array = utf8Encoder.encode(svgString);

  // convert the binary string to a base64 encoded string then convert the byte array to a binary string.
  const svgBase64 = btoa(String.fromCharCode(...Array.from(svgUint8Array)));

  return `data:image/svg+xml;base64,${svgBase64}`;
};

export const imageDataURLToBlob = (dataURL: string) => {
  return new Promise<Blob>((resolve, reject) => {
    const image = new Image();
    image.src = dataURL;
    image.crossOrigin = 'Anonymous';

    image.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      ctx.drawImage(image, 0, 0);

      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create image blob'));
          return;
        }

        return resolve(blob);
      });
    };
  });
};

export const downloadImageFromDataURL = async (
  dataURL: string,
  fileName: string
) => {
  try {
    const blob = await imageDataURLToBlob(dataURL);
    if (!blob) {
      throw new Error('Failed to convert image to blob');
    }

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Failed to download image: ', error);
    alert('Failed to download image. Check console for error.');
  }
};

export const downloadSVGFromDataURL = (dataURL: string, filename: string) => {
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
