import React from 'react';
import Colors from './Colors';
import Fonts from './Fonts';

const Theme = () => {
  return (
    <>
      <Colors />
      <Fonts />
    </>
  );
};

export default Theme;
