import { getSliceGroqQuery } from '@lam-agency/toolkit/utils';

export default getSliceGroqQuery(`
  mediaItems[] {
    ...,
    asset -> {
      url
    }
  }
`);
