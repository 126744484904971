import React, { useState } from 'react';
import {
  PlaygroundWrapper,
  TInspectorDirectory
} from '@lam-agency/toolkit/components';
import { camelCaseToTitleCase } from '@lam-agency/toolkit/utils';

export interface IComponentSchemaField {
  title: string;
  type: 'textInput' | 'toggle' | 'options' | 'sliceConfig';
  options?: string[];
}

const usePlayground = (componentSchema: IComponentSchemaField[]) => {
  const initialConfig: any = {};

  componentSchema?.forEach((field) => {
    switch (field.type) {
      case `toggle`:
        initialConfig[field.title] = false;
        break;
      case `textInput`:
        initialConfig[field.title] = camelCaseToTitleCase(field.title);
        break;
      case `options`:
        initialConfig[field.title] = field.options?.[0];
        break;
      case `sliceConfig`:
        break;
      default:
        throw new Error(
          `Error in usePlayground: field type not recognised while building initial config.`
        );
    }
  });

  const [config, setConfig] = useState(initialConfig);

  const updateConfig = (field: string, value: any) => {
    setConfig((prev: any) => ({
      ...prev,
      [field]: value
    }));
  };

  const renderPlayground = (
    previewComponent: JSX.Element,
    directory: TInspectorDirectory
  ) => {
    return (
      <PlaygroundWrapper
        componentSchema={componentSchema}
        config={config}
        previewComponent={previewComponent}
        updateConfig={updateConfig}
        directory={directory}
      />
    );
  };

  return { config, renderPlayground };
};

export default usePlayground;
