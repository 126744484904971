import React, { CSSProperties } from 'react';
import * as styles from './styles.module.scss';
import { hexToRGB } from '@lam-agency/toolkit/utils';
import cn from 'classnames';

export interface IGridTileWrapper {
  children?: React.ReactNode;
  tileColor?: string;
  borderColor?: string;
  noPadding?: boolean;
}

const GridTileWrapper = ({
  borderColor,
  tileColor = `#ffffff`,
  children,
  noPadding
}: IGridTileWrapper) => {
  const getTextColor = () => {
    const rgb = hexToRGB(tileColor);
    const { blue, green, red } = rgb;

    // Calculate the brightness using the formula: (R * 299 + G * 587 + B * 114) / 1000
    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

    return brightness > 128 ? `black` : `white`;
  };

  const borderStyle: CSSProperties = {
    border: `1px solid ${borderColor || tileColor}`
  };

  return (
    <div
      className={styles.gridItem}
      style={{
        background: tileColor,
        color: getTextColor(),
        ...borderStyle
      }}
    >
      <div className={cn(styles.content, { [styles.noPadding]: noPadding })}>
        {children}
      </div>
    </div>
  );
};

export default GridTileWrapper;
