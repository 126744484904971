// todo - this should replace the Grid component
import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  grid?: boolean;
  paddingX?: boolean;
  paddingY?: boolean;
  maxWidth?: boolean;
}

const LayoutProvider = ({
  children,
  className,
  grid,
  maxWidth,
  paddingX,
  paddingY
}: IProps) => {
  return (
    <div
      className={cn(styles.container, className, {
        [styles.maxWidth]: maxWidth,
        [styles.grid]: grid,
        [styles.paddingX]: paddingX,
        [styles.paddingY]: paddingY
      })}
    >
      {children}
    </div>
  );
};

export default LayoutProvider;
