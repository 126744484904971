import React from 'react';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
}

const FigmaSlice = ({ children }: IProps) => {
  return <section className={styles.container}>{children}</section>;
};

export default FigmaSlice;
