import React from 'react';
import { Button } from '@lam-agency/toolkit/components';
import { usePlayground } from '@lam-agency/toolkit/hooks';
import { IComponentSchemaField } from '@lam-agency/toolkit/hooks/usePlayground';

const PgButton = () => {
  const schemaFields: IComponentSchemaField[] = [
    {
      title: `buttonText`,
      type: `textInput`
    },
    // {
    //   title: `invertColors`,
    //   type: `toggle`
    // },
    {
      title: `fluid`,
      type: `toggle`
    },
    {
      title: `disabled`,
      type: `toggle`
    },
    {
      title: `loading`,
      type: `toggle`
    },
    {
      title: `iconLeft`,
      type: `toggle`
    },
    {
      title: `iconRight`,
      type: `toggle`
    },
    {
      title: `variant`,
      type: `options`,
      options: [`primary`, `secondary`, `text`]
    }
  ];

  const { config, renderPlayground } = usePlayground(schemaFields);

  const previewComponent = (
    <Button
      {...config}
      iconLeft={config.iconLeft ? `arrowLeft` : null}
      iconRight={config.iconRight ? `arrowRight` : null}
      componentName="Button"
    >
      {config.buttonText}
    </Button>
  );

  return <>{renderPlayground(previewComponent, `components`)}</>;
};

export default PgButton;
