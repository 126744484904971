import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";
import { IPlatformData, platforms } from "./data";
import { IMediaTemplate } from "./components/MediaGallery";

export type TScreen =
  | "home"
  | "template-select"
  | "template-config"
  | "template-results"
  | "post-config"
  | "post-results";

export interface IPlatform extends IPlatformData {
  selected: boolean;
}

export interface IMagicPenContext {
  screen: TScreen;
  setScreen: React.Dispatch<TScreen>;
  selectedPromptTemplate: string;
  setSelectedPromptTemplate: React.Dispatch<React.SetStateAction<string>>;
  constructedPrompt: string;
  setConstructedPrompt: React.Dispatch<React.SetStateAction<string>>;
  postTopic: string;
  setPostTopic: React.Dispatch<React.SetStateAction<string>>;
  postPlatforms: IPlatform[];
  setPostPlatforms: React.Dispatch<React.SetStateAction<IPlatform[]>>;
  selectedMedia: IMediaTemplate | null;
  setSelectedMedia: React.Dispatch<React.SetStateAction<IMediaTemplate | null>>;
  showMediaLibrary: boolean;
  setShowMediaLibrary: React.Dispatch<React.SetStateAction<boolean>>;
  imageText: string;
  setImageText: React.Dispatch<React.SetStateAction<string>>;
}

export const MagicPenContext = createContext<IMagicPenContext>(
  {} as IMagicPenContext
);

interface IProps {
  children: React.ReactNode;
}

export const MagicPenProvider = ({ children }: IProps) => {
  const getInitialPlatforms: () => IPlatform[] = () => {
    return platforms.map(({ title, examples }, i) => ({
      title,
      examples,
      selected: i === 0
    }));
  };

  const [screen, setScreen] = useState<TScreen>("home");
  const [selectedPromptTemplate, setSelectedPromptTemplate] = useState("");
  const [constructedPrompt, setConstructedPrompt] = useState("");
  const [postTopic, setPostTopic] = useState("");
  const [postPlatforms, setPostPlatforms] = useState(getInitialPlatforms());
  const [selectedMedia, setSelectedMedia] = useState<IMediaTemplate | null>(
    null
  );
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [imageText, setImageText] = useState("Caption\nGoes Here");

  useEffect(() => {
    if (selectedPromptTemplate) {
      setScreen("template-config");
    }
  }, [selectedPromptTemplate]);

  useEffect(() => {
    if (constructedPrompt) {
      setScreen("template-results");
    }
  }, [constructedPrompt]);

  useEffect(() => {
    if (postTopic) {
      setScreen("post-results");
    }
  }, [postTopic]);

  const contextProps = useMemo(
    () => ({
      screen,
      setScreen,
      selectedPromptTemplate,
      setSelectedPromptTemplate,
      constructedPrompt,
      setConstructedPrompt,
      postTopic,
      setPostTopic,
      postPlatforms,
      setPostPlatforms,
      selectedMedia,
      setSelectedMedia,
      showMediaLibrary,
      setShowMediaLibrary,
      imageText,
      setImageText
    }),
    [
      screen,
      setScreen,
      selectedPromptTemplate,
      setSelectedPromptTemplate,
      constructedPrompt,
      setConstructedPrompt,
      postTopic,
      setPostTopic,
      postPlatforms,
      setPostPlatforms,
      selectedMedia,
      setSelectedMedia,
      showMediaLibrary,
      setShowMediaLibrary,
      imageText,
      setImageText
    ]
  );

  return (
    <MagicPenContext.Provider value={contextProps}>
      {children}
    </MagicPenContext.Provider>
  );
};

MagicPenProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useMagicPenContext = (): IMagicPenContext => useContext(MagicPenContext);
export default useMagicPenContext;
