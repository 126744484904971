import React from 'react';
import {
  SliceConfig,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { IToolkitSliceConfig } from '@lam-agency/sanity';

interface IProps {
  data: {
    title: string;
    sliceConfig: IToolkitSliceConfig;
  };
  componentName?: string;
}

const Hero = ({ data: { title, sliceConfig } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.textContainer}>
        <IntersectionAnimation>
          <h1 className="d1">{title}</h1>
        </IntersectionAnimation>
      </div>
    </SliceConfig>
  );
};

export default Hero;
