import React from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { ISliceHeroText } from 'lam-toolkit-figma-plugin';

const FigmaHeroText = ({ body, backgroundColorHex }: ISliceHeroText) => {
  if (!body) return null;

  return (
    <div style={{ background: backgroundColorHex, color: 'var(--color-navy)' }}>
      <LayoutProvider grid paddingX paddingY>
        <div className={styles.textWrapper}>
          <IntersectionAnimation animation="fade" delay={300}>
            <p className={cn(body.typestyle, styles.text)}>{body.content}</p>
          </IntersectionAnimation>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default FigmaHeroText;
