import React from 'react';
import { Helmet } from 'react-helmet';
import { useMVT } from '@lam-agency/toolkit/stores';

export const StyleTokens = () => {
  const { styleTokens } = useMVT();

  let css = `:root{`;

  styleTokens.forEach(({ key, value }) => {
    css += `--${key}: ${value}; `;
  });

  css += `}`;

  return (
    <Helmet>
      <style>{css}</style>
    </Helmet>
  );
};
