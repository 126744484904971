import { getSliceGroqQuery } from '@lam-agency/toolkit/utils';

export default getSliceGroqQuery(`
  ...,  
  links [] {
    ...,
    reference -> {
      slug {
        current
      }
    }
  }
`);
