import { IFontVariable } from '@lam-agency/toolkit/types';
import { getFontWeightFromStyle } from '@lam-agency/toolkit/utils';
import { SupabaseClient } from '@supabase/supabase-js';

const getFontFamilyCss = ({
  fonts,
  projectString,
  client
}: {
  fonts: IFontVariable[];
  projectString: string;
  client: SupabaseClient<any, 'public', any> | null;
}) => {
  let cssString = ``;

  fonts.forEach((font) => {
    const fontAssetPath = `${projectString || ''}/${font.fileName}`;

    const fontAssetUrl = client?.storage
      .from('fonts')
      .getPublicUrl(fontAssetPath).data.publicUrl;

    cssString += `
      @font-face {
        font-family: "${font.fontFamily}";
        src: url(${fontAssetUrl}) format("${font.format}");
        font-weight: ${getFontWeightFromStyle(font.fontStyle)};
        font-display: swap;
      }
    `;
  });

  return cssString;
};

export default getFontFamilyCss;
