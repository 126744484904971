import React from 'react';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider,
  Link
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { getWhiteIfBackgroundIsTransparent } from '@lam-agency/toolkit/utils';
import type { ILinkAndResourcesSection } from 'lam-toolkit-figma-plugin';

interface IProps {
  data: ILinkAndResourcesSection;
  borderTop?: boolean;
  delayOffset?: number;
}

const ResourceCards = ({
  data: { tiles, title },
  borderTop = false,
  delayOffset = 0
}: IProps) => {
  if (!tiles?.[0]) return null;

  return (
    <LayoutProvider
      className={cn(styles.container, { [styles.borderTop]: borderTop })}
      grid
    >
      <h4 className={cn('b1', styles.heading)}>{title}</h4>

      <div className={styles.cards}>
        {tiles.map(({ description, title, url }, i) => {
          const delay = (delayOffset + i) * 50;

          return (
            <IntersectionAnimation key={i} delay={delay} animation="fadeGrow">
              <div
                style={{ background: getWhiteIfBackgroundIsTransparent() }}
                className={styles.cardWrapper}
              >
                <div aria-hidden className={styles.background} />

                <Link className={styles.card} url={url}>
                  <p className="caption">{description}</p>

                  <div className={cn('b1', styles.cardBody)}>
                    <p>{title}</p>

                    <SVG
                      className={styles.svg}
                      svg={
                        url.includes('dropbox.com')
                          ? 'download'
                          : 'externalLink'
                      }
                    />
                  </div>
                </Link>
              </div>
            </IntersectionAnimation>
          );
        })}
      </div>
    </LayoutProvider>
  );
};

export default ResourceCards;
