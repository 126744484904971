import React from 'react';
import { FadeGrow } from './animations/FadeGrow';
import { FadeUp } from './animations/FadeUp';
import { Typed } from './animations/Typed';
import { WordSwipeUp } from './animations/WordSwipeUp';
import { ColorBump } from './animations/ColorBump';

export type TElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
export type TAnimation =
  | 'typed'
  | 'fade-up'
  | 'fade-grow'
  | 'word-swipe-up'
  | 'color-bump'
  | null;

interface IProps {
  text: string;
  element?: TElement;
  animation?: TAnimation;
  className?: string;
  speed?: number;
  trigger?: boolean;
}

const TextAnimation = ({
  text,
  className,
  speed = 0.02,
  animation = 'typed',
  element = 'p',
  trigger
}: IProps) => {
  return (
    <>
      {animation === 'fade-grow' && (
        <FadeGrow
          text={text}
          className={className}
          element={element}
          speed={speed}
          key={text}
        />
      )}

      {animation === 'fade-up' && (
        <FadeUp
          text={text}
          className={className}
          element={element}
          speed={speed}
          key={text}
        />
      )}

      {animation === 'typed' && (
        <Typed
          text={text}
          className={className}
          element={element}
          speed={speed}
          key={text}
          trigger={trigger}
        />
      )}

      {animation === 'color-bump' && (
        <ColorBump
          text={text}
          className={className}
          element={element}
          key={text}
        />
      )}

      {animation === 'word-swipe-up' && (
        <WordSwipeUp
          text={text}
          element={element}
          className={className}
          key={text}
        />
      )}
    </>
  );
};

export default TextAnimation;
