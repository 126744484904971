import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { LayoutProvider } from '@lam-agency/toolkit/components';
import {
  usePageColorScheme,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import { Link } from 'gatsby';
import { SVG } from '@lam-agency/toolkit/components';

const Header = () => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const pageColorScheme = usePageColorScheme();
  const { isScrollingDown, scrollY } = useScroll();
  const { height } = useWindowDimensions();

  const scrollThresholdPx = height;

  const amountToScrollBeforeFillPx = 300; // sync with amountToScrollBeforeBlurPx in FigmaCopyOnImage.tsx
  const showBackgroundColor = scrollY >= amountToScrollBeforeFillPx;

  useEffect(() => {
    const isScrollingUp = !isScrollingDown;

    setIsHeaderVisible(() => {
      if (isScrollingUp) {
        return true;
      }

      if (scrollY > scrollThresholdPx) {
        return false;
      }

      return true;
    });
  }, [scrollY, isScrollingDown, scrollThresholdPx]);

  return (
    <header
      className={cn(styles.header, {
        [styles.lightColorScheme]: pageColorScheme === `light`,
        [styles.backgroundColor]: showBackgroundColor,
        [styles.hidden]: !isHeaderVisible
      })}
    >
      <LayoutProvider paddingX className={styles.layout}>
        <Link aria-label="Home" to="/" className={styles.homeLink}>
          <SVG svg="logo" className={styles.logo} />
        </Link>

        <a className="button" href="mailto:brian@finamp.vc">
          Contact
        </a>
      </LayoutProvider>
    </header>
  );
};

export default Header;
