import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { INotification } from "./components/Notification";
import {
  IPaletteColor,
  IPaletteImage,
  IPaletteLogo,
  IPaletteText,
  IPaletteVideo
} from "./types";

export interface IPaletteContext {
  isCreateModalOpen: boolean;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notification: INotification;
  setNotification: React.Dispatch<React.SetStateAction<INotification>>;
  paletteContent: IPaletteContent;
  setPaletteContent: React.Dispatch<React.SetStateAction<IPaletteContent>>;
}

export const PaletteContext = createContext<IPaletteContext>(
  {} as IPaletteContext
);

interface IProps {
  children: React.ReactNode;
}

interface IPaletteContent {
  videos: IPaletteVideo[];
  text: IPaletteText[];
  images: IPaletteImage[];
  colors: IPaletteColor[];
  logos: IPaletteLogo[];
}

const PaletteProvider = ({ children }: IProps) => {
  const initialPaletteContent: IPaletteContent = {
    videos: [],
    text: [],
    colors: [],
    images: [],
    logos: []
  };

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [paletteContent, setPaletteContent] = useState<IPaletteContent>(
    initialPaletteContent
  );

  const [notification, setNotification] = useState<INotification>({
    content: ``,
    keyToggle: 0
  });

  // ---------------------------------------------------------------------------
  // render

  const contextProps = useMemo(
    () => ({
      isCreateModalOpen,
      setIsCreateModalOpen,
      notification,
      setNotification,
      paletteContent,
      setPaletteContent
    }),
    [
      isCreateModalOpen,
      setIsCreateModalOpen,
      notification,
      setNotification,
      paletteContent,
      setPaletteContent
    ]
  );

  return (
    <PaletteContext.Provider value={contextProps}>
      {children}
    </PaletteContext.Provider>
  );
};

PaletteProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default PaletteProvider;

export const usePaletteContext = (): IPaletteContext => {
  const paletteContext = useContext(PaletteContext);

  return paletteContext;
};
