import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  className?: string;
}

export const Partition = ({ className }: IProps) => {
  return <div aria-hidden className={cn(styles.partition, className)} />;
};
